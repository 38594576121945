<template class="b-card">
  <b-card>

    <!-- Loader -->
    <b-row v-if="isLoading" class="py-5">
      <loading
        :active="true" 
        :is-full-page="false"
        :color="colors.primary"
      />
    </b-row>

    <!--Form-->
    <validation-observer v-else ref="form" tag="form">
      <b-form>
        <b-row>

          <!-- Title -->
          <b-col md="6">
            <b-form-group :label="$t('label.Title')" :label-for="$t('label.Title')">
              <validation-provider :name="$t('label.Title')" rules="required" v-slot="{ errors }">
                <b-form-input
                  id="name" trim
                  :placeholder="$t('placeholder.title')"
                  v-model="addOrEditComm.title">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Locations -->
          <b-col md="4">
            <b-form-group :label="$t('label.Locations')" :label-for="$t('label.location')">
              <validation-provider :name="$t('label.Locations')" rules="required" v-slot="{ errors }">
                <v-select
                  v-if="!allLocations"
                  id="location"
                  :reduce="(locations) => locations.value"
                  :options="locations"
                  :multiple="true"
                  label="title"
                  :placeholder="$t('placeholder.locations')"
                  v-model="selectedLocations"
                  :disabled="allLocations"
                >
                </v-select>
                <span v-else>{{ $t('label.allSelectedLocations') }}</span>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- All locations -->
          <b-col md="2">
            <b-form-group :label="$t('message.allLocations')" label-for="all-locations" class="d-flex d-md-block flex-direction-row">
              <b-form-checkbox
                id="checkbox-1"
                name="checkbox-1"
                :value="true"
                :unchecked-value="false"
                style="transform: scale(1.1); margin-left: .5rem;"
                @change="handleCheckboxChange"
              >
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
          
        <b-row>
          <!-- Date range -->
          <b-col md="6">
            <b-form-group :label="$t('message.tableHeader.dateRange')" label-for="v-date">
              <flat-pickr
                v-model="dateRange"
                class="form-control"
                :config="datePickerConfig"
              />
            </b-form-group>
          </b-col>

          <!-- Image -->          
          <b-col md="6">
            <b-form-group label-for="v-img">
              <b-row>
                <b-col md="6" v-if="addOrEditComm.imageKey && !imgData">
                  <label>{{ addOrEditComm.imageKey && !imgData ? $t('label.currentImage') : $t('label.img') }}</label>
                  <b-row class="pl-1">
                    <b-button
                      style="width: 7rem; margin-right: 1rem; margin-bottom: .5rem;"
                      variant="outline-primary"
                      @click="openImage(addOrEditComm.imageKey)"
                    > {{ $t('message.open') }}
                    </b-button>
                    <b-button
                      style="width: 7rem; margin-bottom: .5rem;"
                      variant="outline-danger"
                      @click="deleteImage()"
                    > {{ $t('message.delete') }}
                    </b-button>
                  </b-row>
                  <b-col md="12" class="p-0" v-if="imgName && !imgData" style="margin-top: .5rem;">
                    <feather-icon
                      icon="PaperclipIcon"
                      size="15" 
                      style="opacity: 0.5; margin-right: .5rem"
                    />
                    <small class="text-secondary">{{ imgName.length > 50 ? imgName.substring(0, 50) + '...' : imgName }}</small>
                  </b-col>
                </b-col>

                <b-col md="6">
                  <label>{{ addOrEditComm.imageKey && !imgData ? $t('label.newImage') : $t('label.img') }}</label>
                  <b-row>
                    <b-button
                      class="ml-1"
                      :variant="imgData ? 'outline-secondary' : 'outline-info'"
                      @click="uploadImage()"
                    > {{ imgData ? $t('message.modify') : $t('message.upload') }}
                    </b-button>
                  </b-row>
                </b-col>
                <b-col md="12" v-if="imgData" style="margin-top: .5rem;">
                  <feather-icon
                    icon="PaperclipIcon"
                    size="15" 
                    style="opacity: 0.5; margin-right: .5rem"
                  />
                  <small class="text-secondary">{{ imgData.name.length > 50 ? imgData.name.substring(0, 50) + '...' : imgData.name }}</small>
                </b-col>
              </b-row>
            </b-form-group>
            <image-compressor
              ref="compressor"
              style="display: none"
              :done="getImg"
            />
          </b-col>
        </b-row>
        
        <b-row b-col md="12">
          <!-- Detail -->
          <b-col>      
            <b-form-group :label="$t('message.tableHeader.detail')" :label-for="$t('message.tableHeader.detail')">
                <validation-provider :name="$t('message.tableHeader.detail')" rules="required" v-slot="{ errors }">
                  <quill-editor
                    :placeholder="$t('message.tableHeader.detailMsg')"
                    v-model="addOrEditComm.detail"
                    :options="editorOptions"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      
        <!-- Submit -->
        <b-col class="text-right mt-3 pr-0" cols="12">
          <b-button
            variant="primary"
            @click="validateAndSubmit(null, 'edit')"
          > {{ $t('message.submit') }}
          </b-button>
        </b-col>

      </b-form>
    </validation-observer>
  </b-card>
</template>
    
<script>
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { BFormDatepicker, BFormTextarea, BFormCheckbox } from 'bootstrap-vue'
import flatPickr from "vue-flatpickr-component"
import ImageCompressor from '@/views/habit/ImageCompressor.vue'
import useCommunications from './useCommunications'
import { quillEditor } from 'vue-quill-editor'
import { onMounted } from '@vue/composition-api'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { colors } from '@/constants'

export default {
  components: {
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BFormDatepicker,
    BFormTextarea,
    BFormCheckbox,
    flatPickr,
    ImageCompressor,
    quillEditor,
    Loading,
  },

  setup() {

    const {
      locations,
      dateRange,
      form,
      compressor,
      validateAndSubmit,
      datePickerConfig,
      uploadImage,
      getImg,
      addOrEditComm,
      imgData,
      selectedLocations,
      getOneCommunication,
      isLoading,
      openImage,
      editorOptions,
      imgName,
      deleteImage,
      handleCheckboxChange,
      allLocations
    } = useCommunications()


    onMounted(() => {
        getOneCommunication('edit')
    })

    return {
      locations,
      dateRange,
      form,
      compressor,
      validateAndSubmit,
      datePickerConfig,
      uploadImage,
      getImg,
      addOrEditComm,
      imgData,
      selectedLocations,
      editorOptions,
      isLoading,
      openImage,
      imgName,
      deleteImage,
      handleCheckboxChange,
      allLocations,
      colors
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/quill.scss';
</style>
